import { Controller } from "@hotwired/stimulus"
import { getComponent } from "@symfony/ux-live-component"

export default class extends Controller {
    static targets = ["button", "dialog", "frame", "closeBtn"]
    static values = {
        redirectOnClose: String,
    }

    async initialize() {
        this.component = await getComponent(document.getElementById("toast-container"))
    }

    connect() {
        this.element.addEventListener("turbo:submit-end", (event) => {
            this.onSubmitEnd(event)
            this.component.render()
        })
    }

    disconnect() {
        this.element.removeEventListener("turbo:submit-end", this.onSubmitEnd.bind(this))
    }

    onSubmitEnd(event) {
        const { success } = event.detail

        if (success) {
            this.closeDialog(event)
        }
    }

    openDialog() {
        this.dialogTarget.showModal()
        document.body.style.overflowY = "hidden"
    }

    closeDialog(e) {
        if (e.target === this.dialogTarget || this.closeBtnTarget.contains(e.target) || e.type === "turbo:submit-end") {
            this.dialogTarget.close()
            this.buttonTarget.blur()
            document.body.style.overflowY = "auto"

            if (this.redirectOnCloseValue) {
                window.open(this.redirectOnCloseValue, "_blank")
            }
        }
    }

    animationBell(e) {
        e.target.querySelector("svg").classList.toggle("ring-animation")
    }
}
